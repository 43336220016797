import React from 'react';

import sponsorsFooterStyles from './sponsors-footer.module.css';

export default function SponsorsFooter() {
  return (
    <div className={sponsorsFooterStyles.sponsorsFooter}>
      <h2>With support from partners:</h2>

      <ul className={sponsorsFooterStyles.list}>
        <li className={sponsorsFooterStyles.sponsor}>
          <a href="https://www.racingmedia.io" target="_blank" rel="noreferrer" title="RacingMedia.io">
            <img src="/images/sponsors/rmio-stacked-black.png" alt="RacingMedia.io" />
          </a>
        </li>

        <li className={sponsorsFooterStyles.sponsor}>
          <a href="https://www.skyacademy.co/" target="_blank" rel="noreferrer" title="SkyAcademy">
            <img src="/images/sponsors/SkyAcademy-Logo-Mono.jpg" alt="SkyAcademy" />
          </a>
        </li>

        <li className={sponsorsFooterStyles.sponsor}>
          <a href="https://www.ryanmi.com/en/" target="_blank" rel="noreferrer" title="Ryan Motorsport Insurance">
            <img src="/images/sponsors/Ryan_Motorsport_Insurance_logo.svg" alt="Ryan Motorsport Insurance" />
          </a>
        </li>

        <li className={sponsorsFooterStyles.sponsor}>
          <a href="https://www.wallboss.co.uk/" target="_blank" rel="noreferrer" title="Wallboss">
            <img src="/images/sponsors/wallboss-logo.png" alt="Wallboss" />
          </a>
        </li>
      </ul>
    </div>
  );
}
