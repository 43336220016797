import React from 'react';
import { DesktopMenu } from './desktop-menu/desktop-menu';

import headerMenuStyles from './header-menu.module.css';
import MobileMenu from './mobile-menu/mobile-menu';

export default function HeaderMenu({ activePageName }) {
  const isActive = pageName => {
    return pageName === activePageName;
  };

  const menuItems = [
    {
      label: 'Home',
      href: '/',
      isActive: isActive('home'),
    },
    {
      label: 'Calendar',
      href: '/calendar',
      isActive: isActive('calendar'),
    },
    {
      label: 'Race Report',
      href: '/race-report',
      isActive: isActive('race-report'),
    },
    {
      label: 'Contact',
      href: '/contact',
      isActive: isActive('contact'),
    },
  ];

  return (
    <>
      <div className={headerMenuStyles.wrapper}>
        <div className={headerMenuStyles.container}>
          <img
            className={`${headerMenuStyles.logoIcon} animate__animated animate__fadeInDownBig`}
            src="/logo/Icon/Tom_Seldon_Icon_White.svg"
            alt="Logo - TS"
            width="80px"
          />
          <div className={`${headerMenuStyles.logoBg} animate__animated animate__slideInDown`} />

          <DesktopMenu menuItems={menuItems} />
          <MobileMenu menuItems={menuItems} />
        </div>
      </div>
    </>
  );
}
