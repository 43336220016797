import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faYoutube, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import footerStyles from './footer.module.css';

export default function Footer() {
  return (
    <footer>
      <ul className={footerStyles.social}>
        <li className={footerStyles.instagram}>
          <a
            href="https://www.instagram.com/tom_seldon/"
            target="_blank"
            rel="noreferrer"
            title="Instagram: @tom_seldon"
          >
            <FontAwesomeIcon icon={faInstagram} />
            tom_seldon
          </a>
        </li>

        <li className={footerStyles.twitter}>
          <a href="https://www.twitter.com/TomSeldon1" target="_blank" rel="noreferrer" title="Twitter: @TomSeldon1">
            <FontAwesomeIcon icon={faTwitter} />
            TomSeldon1
          </a>
        </li>

        <li className={footerStyles.facebook}>
          <a
            href="https://www.facebook.com/tomseldonracing"
            target="_blank"
            rel="noreferrer"
            title="Facebook: TomSeldonRacing"
          >
            <FontAwesomeIcon icon={faFacebook} />
            TomSeldonRacing
          </a>
        </li>

        <li className={footerStyles.youtube}>
          <a href="https://www.youtube.com/@tomseldon" title="YouTube: @TomSeldon">
            <FontAwesomeIcon icon={faYoutube} />
            tomseldon
          </a>
        </li>

        <li className={footerStyles.email}>
          <a href="mailto:tom@tomseldon.co.uk" title="E-mail: tom@tomseldon.co.uk">
            <FontAwesomeIcon icon={faEnvelope} />
            tom@tomseldon.co.uk
          </a>
        </li>
      </ul>

      <img className={footerStyles.logo} alt="Tom Seldon Racing" src="/logo/text/Tom_Seldon_Racing_Black.png" />
    </footer>
  );
}
