import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import desktopMenuStyles from './desktop-menu.module.css';

export function DesktopMenu({ menuItems }) {
  const leftItems = menuItems.slice(0, 3);
  const rightItems = menuItems.slice(3, 6);

  return (
    <>
      <nav className={desktopMenuStyles.desktopMenu}>
        <ul>
          {leftItems.map(menuItem => (
            <li key={menuItem.href}>
              <a className={menuItem.isActive ? desktopMenuStyles.activeMenuItem : ''} href={menuItem.href}>
                {menuItem.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <nav className={desktopMenuStyles.desktopMenu}>
        <ul>
          {rightItems.map(menuItem => (
            <li key={menuItem.href}>
              <a className={menuItem.isActive ? desktopMenuStyles.activeMenuItem : ''} href={menuItem.href}>
                {menuItem.label}
              </a>
            </li>
          ))}

          <li className={desktopMenuStyles.socialIcon}>
            <a
              href="https://www.instagram.com/tom_seldon/"
              target="_blank"
              rel="noreferrer"
              title="Instagram: @tom_seldon"
            >
              <FontAwesomeIcon icon={faInstagram} width="0.875em" />
            </a>
          </li>

          <li className={desktopMenuStyles.socialIcon}>
            <a href="https://www.youtube.com/@tomseldon" title="YouTube: @TomSeldon">
              <FontAwesomeIcon icon={faYoutube} width="1.125em" />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
