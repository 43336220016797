import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';

import mobileMenuStyles from './mobile-menu.module.css';

export default function MobileMenu({ menuItems }) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const onToggleMenu = () => setMenuIsVisible(!menuIsVisible);

  return (
    <>
      <button
        type="checkbox"
        id="main-navigation-toggle"
        className="btn btn--close"
        title="Toggle main navigation"
        onClick={onToggleMenu}
      />

      <label htmlFor="main-navigation-toggle">
        <span />
      </label>

      <nav
        id="main-navigation"
        className={`${mobileMenuStyles.navMain} ${menuIsVisible ? mobileMenuStyles.visible : ''}`}
      >
        <img
          className={`${mobileMenuStyles.logoIcon} animate__animated animate__fadeInDownBig`}
          src="/logo/Icon/Tom_Seldon_Icon_White.svg"
          alt="Logo - TS"
        />

        <ul className={mobileMenuStyles.menu}>
          {menuItems.map(menuItem => (
            <li className={mobileMenuStyles.menu__item} key={menuItem.href}>
              <a
                className={`${mobileMenuStyles.menu__link} ${
                  menuItem.isActive ? mobileMenuStyles.menu__link_active : ''
                }`}
                href={menuItem.href}
              >
                {menuItem.label}
              </a>
            </li>
          ))}

          <li className={`${mobileMenuStyles.menu__item} ${mobileMenuStyles.menu__itemSocialIcon}`}>
            <a
              href="https://www.instagram.com/tom_seldon/"
              target="_blank"
              rel="noreferrer"
              title="Instagram: @tom_seldon"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>

          <li className={`${mobileMenuStyles.menu__item} ${mobileMenuStyles.menu__itemSocialIcon}`}>
            <a href="https://www.youtube.com/@tomseldon" title="YouTube: @TomSeldon">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
